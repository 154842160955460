import React from 'react'

function Blog() {
  return (
    <div id="Blog">
      <div className="bg-primary">
        {/* Section Here */}
        
      </div>
    </div>
  )
}

export default Blog